import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Divider, Form, Input, Select, Space } from 'antd'
import { translations } from '../../../constants'

const { Option } = Select

const CaForm = ({
  hideModal,
  isFormNeedPaymentMethods = true,
  locale = 'us',
  onSubmit,
}) => {
  const [form] = Form.useForm()
  const [isModalLoading, setModalLoading] = useState(false)

  const handleSubmit = () => {
    const isFormUpdated = form.isFieldsTouched()
    if (isFormUpdated) {
      form
        .validateFields()
        .then(() => {
          setModalLoading(true)
          const requiredFieldsValue = form.getFieldsValue()

          onSubmit({
            country:'ca',
            requiredFieldsValue,
            setModalLoading,
          })
        })
    }
  }

  return (
    <Form
      data-test="bank-details-form"
      data-testid="caForm"
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
    >
      <Form.Item
        className="bankDetailsAccountNumber"
        label={translations[locale].accountNumber}
        name="accountNumber"
        rules={[
          {
            message: 'Please enter your account number!',
            required: true,
          },
          {
            max: 11,
            message: 'Please enter a valid 11-digit IBAN number.',
          },
          {
            message: 'Please enter a valid 11-digit IBAN number.',
            min: 11,
          },
          {
            message: 'Account number should contain only numbers!',
            pattern: /^[0-9]+$/,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        className="bankDetailsAccountType"
        label={translations[locale].accountType}
        name="accountType"
        rules={[
          {
            message: 'Please provide an account type!',
            required: true,
          },
        ]}
      >
        <Select placeholder="CHECKING">
          <Option value="CHECKING">CHECKING</Option>,
          <Option value="SAVINGS">SAVINGS</Option>,
        </Select>
      </Form.Item>

      <Form.Item
        className="bankDetailsInstitutionNumber"
        label={translations[locale].institutionNumber}
        name="institutionNumber"
        rules={[
          {
            message: 'Please make sure your institution number is correct!',
            required: true,
          },
          {
            max: 3,
            message: 'Please enter a valid 3-digit institution number.',
          },
          {
            message: 'Please enter a valid 3-digit institution number.',
            min: 3,
          },
          {
            message: 'Institution number should contain only numbers!',
            pattern: /^[0-9]+$/,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        className="bankDetailsTransitNumber"
        label={translations[locale].transitNumber}
        name="transitNumber"
        rules={[
          {
            message: 'Please make sure your transit number is correct!',
            required: true,
          },
          {
            max: 5,
            message: 'Please enter a valid 5-digit transit number.',
          },
          {
            message: 'Please enter a valid 5-digit transit number.',
            min: 5,
          },
          {
            message: 'Transit number should contain only numbers!',
            pattern: /^[0-9]+$/,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Divider />

      <Space
        size="middle"
        style={{
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <Button className="bankDetailsSubmit" htmlType="submit" loading={isModalLoading} name="submitBtnSelenium" type="primary">{translations[locale].submit}</Button>
        {isFormNeedPaymentMethods &&<Button className="bankDetailsCancel" name="cancelBtnSelenium" onClick={hideModal}>Cancel</Button>}
      </Space>
    </Form>
  )
}

CaForm.propTypes = {
  handleFailure: PropTypes.func.isRequired,
  hideModal: PropTypes.func,
  isFormNeedPaymentMethods: PropTypes.bool,
  locale: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  selectedStore: PropTypes.object.isRequired,
}

export default CaForm
