import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Divider, Form, Input, Space } from 'antd'
import { translations } from '../../../constants'

const UkForm = ({
  hideModal,
  isFormNeedPaymentMethods = true,
  locale = 'us',
  onSubmit,
  selectedStore,
}) => {
  const [form] = Form.useForm()
  const [isModalLoading, setModalLoading] = useState(false)

  const handleSubmit = () => {
    const isFormUpdated = form.isFieldsTouched()
    if (isFormUpdated) {
      form
        .validateFields()
        .then(() => {
          setModalLoading(true)

          const requiredFieldsValue = {
            ...form.getFieldsValue(),
            store_id: selectedStore.id,
          }

          onSubmit({
            country:'uk',
            requiredFieldsValue,
            setModalLoading,
          })
        })
    }
  }

  return (
    <Form
      data-test="bank-details-form"
      data-testid="ukForm"
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
    >
      <Form.Item
        className="bankDetailsSortCode"
        label={translations[locale].sortCode}
        name="sortCode"
        rules={[
          {
            message: 'Please enter your account sort code!',
            required: true,
          },
          {
            max: 6,
            message: 'Please provide a valid sort code consisting of 6 digits.',
          },
          {
            message: 'Please provide a valid sort code consisting of 6 digits.',
            min: 6,
          },
          {
            message: 'Please enter a valid sort code consisting of only numbers.',
            pattern: /^[0-9]+$/,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        className="bankDetailsAccountNumber"
        label={translations[locale].accountNumber}
        name="accountNumber"
        rules={[
          {
            message: 'Please enter your account number!',
            required: true,
          },
          {
            max: 8,
            message: 'Please provide a valid account number consisting of 8 digits.',
          },
          {
            message: 'Please provide a valid account number consisting of 8 digits.',
            min: 8,
          },
          {
            message: 'Please enter a valid account number consisting of only numbers.',
            pattern: /^[0-9]+$/,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Divider />

      <Space
        size="middle"
        style={{
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <Button className="bankDetailsSubmit" htmlType="submit" loading={isModalLoading} name="submitBtnSelenium" type="primary">{translations[locale].submit}</Button>
        {isFormNeedPaymentMethods &&<Button className="bankDetailsCancel" name="cancelBtnSelenium" onClick={hideModal}>Cancel</Button>}
      </Space>
    </Form>
  )
}

UkForm.propTypes = {
  handleFailure: PropTypes.func.isRequired,
  hideModal: PropTypes.func,
  isFormNeedPaymentMethods: PropTypes.bool,
  locale: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  selectedStore: PropTypes.object.isRequired,
}

export default UkForm
