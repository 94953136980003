import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Divider, Form, Input, Select, Space } from 'antd'
import { getBankCode, getBranchCode } from '../api'
import { translations } from '../../../constants'

const { Option } = Select

const JpForm = ({
  handleFailure,
  hideModal,
  isFormNeedPaymentMethods = true,
  locale = 'us',
  onSubmit,
  selectedStore,
}) => {
  const initialValues = { accountType: 'CURRENT', store_id: selectedStore.id }
  const [form] = Form.useForm()
  const [isModalLoading, setModalLoading] = useState(false)
  const [bankCodes, setBankCodes] = useState([])
  const [branchCodes, setBranchCodes] = useState([])

  useEffect(() => {
    fetchBankCode()
  }, [])

  const onBankCodeChange = (e) => {
    form.setFieldsValue({ branchCode: [] })

    fetchBranchCode(e)
  }

  const fetchBankCode = () => {
    setModalLoading(true)
    getBankCode({ params: { country_code: 'jp' }})
      .then(({ data }) => {
        setBankCodes(data.data)
        setModalLoading(false)
      })
      .catch((error) => {
        setModalLoading(false)
        handleFailure(error, 'Fetching bank code failed!')
      })
  }

  const fetchBranchCode = (selectedBank) => {
    setModalLoading(true)
    getBranchCode({ params: { bank_code: selectedBank, country_code: 'jp' }})
      .then(({ data }) => {
        setBranchCodes(data.data)
        setModalLoading(false)
      })
      .catch((error) => {
        setModalLoading(false)

        handleFailure(error, 'Fetching branch code failed!')
      })
  }

  const handleSubmit = () => {
    const isFormUpdated = form.isFieldsTouched()

    if (isFormUpdated) {
      form
        .validateFields()
        .then(() => {
          setModalLoading(true)

          const requiredFieldsValue = {
            ...form.getFieldsValue(),
            store_id: selectedStore.id,
          }

          onSubmit({
            country:'jp',
            requiredFieldsValue,
            setModalLoading,
          })
        })
    }
  }

  return (
    <Form
      data-test="bank-details-form"
      data-testid="jpForm"
      form={form}
      initialValues={initialValues}
      layout="vertical"
      onFinish={handleSubmit}
    >
      <Form.Item
        className="bankDetailsBankCode"
        label={translations[locale].bankCode}
        name="bankCode"
        rules={[
          {
            message: translations[locale].addBankCode,
            required: true,
          },
        ]}
      >
        <Select
          data-testid="bankCode"
          name="bankCode"
          onChange={onBankCodeChange}
          showSearch
        >
          {React.Children.toArray(bankCodes?.map(({ code, title }) =>
            <Option value={code}>{title} [{code}]</Option>,
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        className="bankDetailsBranchCode"
        label={translations[locale].branchCode}
        name="branchCode"
        rules={[
          {
            message: translations[locale].addBranchCode,
            required: true,
          },
        ]}
      >
        <Select
          data-testid="branchCode"
          showSearch
        >
          {React.Children.toArray(branchCodes?.map((branch) =>
            <Option value={branch.code}>{branch.title}</Option>,
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        className="bankDetailsAccountType"
        label={translations[locale].accountType}
        name="accountType"
        rules={[
          {
            message: translations[locale].addAccountType,
            required: true,
          },
        ]}
      >
        <Select>
          <Option value="CURRENT">{translations[locale].current}</Option>,
          <Option value="SAVINGS">{translations[locale].savings}</Option>,
          <Option value="CHECKING">{translations[locale].checking}</Option>,
        </Select>
      </Form.Item>

      <Form.Item
        className="bankDetailsAccountNumber"
        label={translations[locale].accountNumber}
        name="accountNumber"
        rules={[
          {
            message: translations[locale].addAccountNumber,
            required: true,
          },
          {
            message: translations[locale].addValidAccountNumber,
            min: 7,
          },
          {
            max: 7,
            message: translations[locale].addValidAccountNumber,
          },
          {
            message: translations[locale].accountNumberType,
            pattern: /^[0-9]+$/,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Divider />

      <Space
        size="middle"
        style={{
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <Button className="bankDetailsSubmit" htmlType="submit" loading={isModalLoading} name="submitBtnSelenium" type="primary">{translations[locale].submit}</Button>
        {isFormNeedPaymentMethods &&<Button className="bankDetailsCancel" name="cancelBtnSelenium" onClick={hideModal}>Cancel</Button>}
      </Space>
    </Form>
  )
}

JpForm.propTypes = {
  handleFailure: PropTypes.func.isRequired,
  hideModal: PropTypes.func,
  isFormNeedPaymentMethods: PropTypes.bool,
  locale: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  selectedStore: PropTypes.object.isRequired,
}

export default JpForm
